<template>
	<div class="container">
		<div class="head">
			<div class="left">
				<img :src="form.bannerImage" alt="">
			</div>
			<div class="right">
				<div class="title">{{form.bannerName}}</div>
				<!-- <div class="info">{{form.info}}</div> -->
			</div>
		</div>
		<div class="ql-editor" v-html="form.bannerInfo">

		</div>
	</div>
</template>

<script>
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	import { getTranslate } from "@/api/home";
	export default {
		data() {
			return {
				form: {},
			}
		},
		created() {
			this.form = JSON.parse(this.$route.query.item)
			console.log(this.form)
		},
		mounted() {


		},

		methods: {

		}

	}
</script>
<style lang="scss" scoped>
	::v-deep img {
		max-width: 100%;
	}

	.container {
		width: 1020px;
		margin: 50px auto;

		.head {
			overflow: hidden;

			.left {
				float: left;
				width: 500px;

				img {
					width: 500px;
					height: 500px;
				}
			}

			.right {
				float: right;
				width: 500px;

				.title {
					font-size: 26px;
					font-weight: 600;
				}

				.info {
					font-size: 14px;
					padding-top: 30px;
				}

			}
		}
	}
</style>